import React, {useState} from "react"
import PropTypes from "prop-types"
import ImpactBar from "./impact_bar"
import IncidentIcon from "./incident_icon"
import "./incident.scss"
import { isAdmin } from "../../services/auth"
import {navigate} from "gatsby"
import { PinIncident, UnpinIncident } from "../../services/api"

const Incident = ({id, type, country, region, city, current, impact, start_date, reporting_date, zipcodes, description, sourceName, sourceUrl, pinned, delay, isHoliday = false}) => {
	const [pinnedState, setPinnedState] = useState(null)

	const unpin = (e) => {
		setPinnedState(false)
		UnpinIncident(id).then((result) => {
			if(result.Success) {
				setPinnedState(result.IsPinned)
			}
		})
	}

	const pin = (e) => {
		setPinnedState(true)
		PinIncident(id).then((result) => {
			if(result.Success) {
				setPinnedState(result.IsPinned)
			}
		})
	}

	let adminOptions = []
	if(!isHoliday && isAdmin()) {
		adminOptions = [
			<a href={"#/admin/edit/"+id} key="1" onClick={(e) => { e.preventDefault(); window.open('/edit/?id=' + id) }} className="icon admin-edit" rel="noopener noreferrer">
				
			</a>]
		if( (pinnedState == null && pinned) || pinnedState === true) {
			adminOptions.push(
				<a href="#" key="2" className="icon admin-pin unpin" onClick={unpin}>
					
				</a>
			)
		} else {
			adminOptions.push(
				<a href="#" key="3" className="icon admin-pin pin" onClick={pin}>
					
				</a>
			)
		}
	} else if (pinned) {
		adminOptions = [
			<a href="#" key="1" className="icon admin-pin unpin">
					
				</a>
		]
	}
  return (
	 	<div className="incident">
			<header className="with-icon">
				<IncidentIcon type={isHoliday ? "holiday" : type} impact={impact} />
				<h5>
					<strong>{type}</strong> in {country}
					{!isHoliday && <small>{region} {city}</small>}
				</h5>
				{!isHoliday && <span className="current-label">{current ? "Current" : "Predicted"}</span>}
				{adminOptions}
			</header>
			{!isHoliday && <ImpactBar impact={impact} />}
			

			<div className="properties row">
				<div className="col-md-6 col-xs-12">
					<div className="the-date">{ start_date }</div>
					<label>Start date</label>
				</div>
				<div className="col-md-6 col-xs-12">
					<div className="delay the-date">{ delay }</div>
					<label>Potential delay</label>
				</div>
			</div>
			{!isHoliday && <div className="postcodes">
				Affected postcodes: <strong>{zipcodes}</strong>
			</div>}
			

			<div className="context">
				<label>Event description</label>
				<p>{description}</p>
			</div>

			{!isHoliday && 
				<ul>
					<li className="source" key="source"><a href={sourceUrl} target="_blank" rel="nofollow">{sourceName}</a></li>
					<li className="reporting-date" key="reporting_date">{ reporting_date }</li>
				</ul>
			}
	 	</div>
  )
}

Incident.propTypes = {
	  id: PropTypes.number.isRequired,
	  type: PropTypes.string.isRequired,
	  country: PropTypes.string.isRequired,
	  region: PropTypes.string.isRequired,
	  city: PropTypes.string.isRequired,
	  current: PropTypes.bool.isRequired,
	  impact: PropTypes.string.isRequired,
	  start_date: PropTypes.string.isRequired,
	  reporting_date: PropTypes.string.isRequired,
	  zipcodes: PropTypes.string.isRequired,
	  description: PropTypes.string.isRequired,
	  sourceName: PropTypes.string.isRequired,
	  sourceUrl: PropTypes.string.isRequired,
	  pinned: PropTypes.bool,
	  delay: PropTypes.string,
		isHoliday: PropTypes.bool,
}
export default Incident