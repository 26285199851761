import * as React from "react"
import {getUser} from "../../services/auth"

const Welcome = () => {
  const user = getUser()
  return (
	 <p className="welcome-msg">
		<strong>Hi {user.name}</strong>, welcome back
	 </p>
  )
}


export default Welcome