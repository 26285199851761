import {useState, useEffect } from 'react'
import { getCountries } from "../../services/api"

function useFilters(propFilters = {}, propCountries = []){
  const [filters, setFilters] = useState(propFilters);
  const [countries, setCountries] = useState(propCountries);
	const [countriesPerContinent, setCountriesPerContinent] = useState({})
  

  useEffect(() =>{
    getCountries(true).then(countries => {
      setCountriesPerContinent(countries);
    })
  }, [])

  const countryController = {
    getContinentCountries : (continent) =>{
      if(continent != null && countriesPerContinent[continent]){
        return countriesPerContinent[continent]
      }else{
        return null
      }
    },
    addCountries : (selected) => {
      let newCountries = [...selected]
      setCountries(newCountries)
    },
    setAll : (continent) => {
      if(continent != null && countriesPerContinent[continent]) {
        let codes = [...countries]
        for(const key in countriesPerContinent[continent]) {
          let country = countriesPerContinent[continent][key]

          codes.push(country.code)
        }
        countryController.addCountries(codes)
      }
    },
    setNone : (continent) => {
      if(continent != null && countriesPerContinent[continent]) {
        let codes = [...countries]
        const countryCodes = countriesPerContinent[continent].map(value => {return value.code});
        codes = codes.filter((country)=>{return !(countryCodes.includes(country))});

        countryController.addCountries(codes)
      }
    },
    addCountry : (country) => {
      if(countries.includes(country)){
        countries.splice(countries.indexOf(country),1)
      }else{
        countries.push(country);
      }
      countryController.addCountries(countries)
    },
    setCountries : (countries) => {
      setCountries(countries)
      setFilters({...filters,
      countries: countries})
    }
  }

  const filterController = {
    toggleFilter: (key, filter) => {
      if(key == 'reset') {
        if(filter == "NDD"){
          setFilters({source:['PostNL ISA','PostNL Supply Chain']})
        }else{
          setFilters({})
        }
        setCountries([])
        return;
      }
      let newFilters = {...filters}
  
      if (typeof(filter) === 'object'){
        newFilters[key] = filter
      }else{
        if(newFilters[key] && newFilters[key].includes(filter)) {
          newFilters[key] = newFilters[key].filter(f => f != filter)
        } else { 
          if(!newFilters[key]) {
            newFilters[key] = []
          }
          newFilters[key].push(filter)
        }
      }

      setFilters(newFilters)
    },
    mapFilterCallback: (key, filter) => {
      let newFilters = {...filters}
      if (typeof(filter) === 'object'){
        newFilters[key] = filter
      }else{
        if(newFilters[key] && newFilters[key].includes(filter)) {
          newFilters[key] = newFilters[key].filter(f => f !== filter)
        } else { 
          if(!newFilters[key]) {
            newFilters[key] = []
          }
          newFilters[key].push(filter)
        }
      }
        
      filterController.setFilters(newFilters)
      if(typeof(filterCallback) != 'undefined') {
        filterController.toggleFilter(key, filter)
      }
    },
    orderBy: (order) =>{
      setFilters({...filters, orderBy : order})
    },
    setCountry : (country) =>{ 
      if(!country){
        countryController.setCountries([])
      }else{
        countryController.setCountries([country])
      }
    },
    setFilters : setFilters,
  }

  return [filters, countries, filterController, countryController]
}

export default useFilters









