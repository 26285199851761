import * as React from "react"
import IncidentType from "./incident_type"

const IncidentTypes = () => {

  return (
	 <div className="row bubblecharts g-0">
		 <IncidentType size="large" impact="high" />
		 <IncidentType size="medium" impact="medium" />
		 <IncidentType size="medium" impact="low" />
	 </div>
  )
}


export default IncidentTypes