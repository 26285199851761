
import React, {useState} from "react"
import PropTypes from "prop-types"

const IncidentIcon = ({type, impact, onMap, onHoverCallback, onHoverOutCallback, id, size, zoom, onClickCallback}) => {
	const doMouseOver = () => {
		if(onHoverCallback) {
			onHoverCallback(id)
		}
	}

	const doMouseOut = () => {
		if(onHoverOutCallback) {
			onHoverOutCallback(id)
		}
	}

	const doClick = () => {
		if(onClickCallback) {
			onClickCallback(id)
		}
	}

	let styles = {}
	if(onMap) {
		styles = {
			display: "block"
		}
	}
	
	if (typeof(zoom) == 'undefined') {
		zoom = 3
	}

	return (
		<i style={styles} className={"incident-icon " + type.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '') + " " + impact} onMouseOver={doMouseOver} onMouseOut={doMouseOut} onClick={doClick}></i>
	)
}

IncidentIcon.propTypes = {
	type: PropTypes.string.isRequired,
	impact: PropTypes.string.isRequired,
	onMap: PropTypes.bool,
	onHoverCallback: PropTypes.func,
	onHoverOutCallback: PropTypes.func,
	id: PropTypes.number,
	showHeatmap: PropTypes.bool,
	size: PropTypes.string,
	zoom: PropTypes.number,
	onClickCallback: PropTypes.func
}

export default IncidentIcon