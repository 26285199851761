import * as React from "react"
import PropTypes from "prop-types"

const StatisticsBox = ({size, number, label, color, onClick}) => {
	let className = 'col-md-4 col-sm-12 statbox'
	if(size == 'large') {
		className = 'statbox large'
	}
	className += ' ' + color

	const firstWord = label.replace(/ .*/,'')
	const otherWords = label.substring(firstWord.length, label.length)

  return (
	 <div className={className} onClick={onClick}>
		 <span className="number">{number}</span>
		 <span className="label"><strong>{firstWord}</strong> {otherWords}</span>
	 </div>
  )
}

StatisticsBox.propTypes = {
	size: PropTypes.string,
	number: PropTypes.any,
	label: PropTypes.string,
	color: PropTypes.string,
	onClick: PropTypes.func
 }

export default StatisticsBox