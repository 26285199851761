import React, {useEffect, useState} from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Dashboard from "../components/dashboard/dashboard"
import IncidentsBar from "../components/incidents/incidents_bar"
import { navigate } from "gatsby"
import { getIncidents } from "../services/api"
import useFilters from "../components/common/filters"
import { hasDashboardAccess } from "../services/auth"


const DashboardPage = () => {
  const [filters, countriesSelected, filterController, countryController] = useFilters();

  const [incidents, setIncidents] = useState([])
  const [loading, setLoading] = useState(true)


  const loadIncidents = () => {
    setLoading(true)

    getIncidents(filters).then(incidents => {
			if(incidents != null) {
				setIncidents(incidents)
			} else {
        setIncidents([])
      }
      setLoading(false)
		})
  }

  useEffect(()=>{
    loadIncidents()
  },[filters])

  if(!hasDashboardAccess() && typeof window != 'undefined'){
    navigate("/404")
    return (<></>)
  }

  const changeImpact = (impact) => {
    filterController.setFilters({
      ...filters,
      impact: [impact]
    })
  }

  const changeStatus = (status) => {
    filterController.setFilters({
      ...filters,
      status: [status]
    })
  }


  
  return (
    <Layout>
      <Seo title="PostNL Early Warning System" />
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <Dashboard impactCallback={changeImpact} statusCallback={changeStatus} />
          </div>

          <aside className="alert-sidebar col-md-4 col-sm-12">
            <IncidentsBar overrideFilters={filters} 
            close={false} 
            incidents={incidents} 
            loading={loading} 
            filterController={filterController}/>
          </aside>
        </div>
      </div>
    </Layout>
  )
}

export default DashboardPage
