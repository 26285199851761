import React, {useState} from "react"
import PropTypes from "prop-types"

import "./impact_bar.scss"

const ImpactBar = ({impact}) => {
	const classNames = "col-md-4 col-sm-4 "
	let low_class = "", medium_class = "", high_class = ""

	if(impact == "low") {
		low_class = " with-arrow "
	} else if(impact == "medium") {
		medium_class = " with-arrow "
	} else {
		high_class = " with-arrow "
	}
  return (
	  <>
	 	<div className="impact-bar">
			 <div className="impact-bar-wrapper">
				<div className={classNames + low_class + "low" }>
					<div className="inner"></div>
				</div>
				<div className={classNames + medium_class + "medium" }>
					<div className="inner"></div>
				</div>
				<div className={classNames + high_class + "high" }>
					<div className="inner"></div>
				</div>
			</div>
			<div className="col-md-3 hidden-sm label">Impact</div>
		</div>
		</>
  )
}

ImpactBar.propTypes = {
	  impact: PropTypes.string.isRequired
}

export default ImpactBar