import React, {useState} from "react"
import { getStatistics } from "../../services/api";
import StatisticsBox from "./statistics_box"

const Statistics = ({clickCallbackImpact, clickCallbackStatus}) => {
	const [statistics, setStatistics] = useState(null);

	if(!statistics || Object.keys(statistics).length === 0) {
		getStatistics().then(response => {
			setStatistics(response);
		})
	}

	const setImpact = (impact) => {
		if(typeof clickCallbackImpact != "undefined") {
			clickCallbackImpact(impact);
		}
	}

	const setStatus = (status) => {
		if(typeof clickCallbackStatus != "undefined") { 
			clickCallbackStatus(status);
		}
	}

  return (
	 	<div className="row stat-boxes g-0">
			 <div className="col-md-3 col-sm-6"> 
			<StatisticsBox size="large" number={statistics != null ? statistics.TotalIncidents : '..'} label="total incidents" color="neutral"></StatisticsBox>
			</div>
			<div className="col-md-9 col-sm-6 row g-0">
				<StatisticsBox size="normal" number={statistics != null ? statistics.LowImpactIncidents: '..'} label="low impact" color="yellow" onClick={(e) => setImpact("low")}></StatisticsBox>
				<StatisticsBox size="normal" number={statistics != null ? statistics.MediumImpactIncidents: '..'} label="medium impact" color="orange" onClick={(e) => setImpact("medium")}></StatisticsBox>
				<StatisticsBox size="normal" number={statistics != null ? statistics.HighImpactIncidents: '..'} label="big impact" color="red" onClick={(e) => setImpact("high")}></StatisticsBox>
				<StatisticsBox size="normal" number={statistics != null ? statistics.PredictedIncidents: '..'} label="predicted incidents" color="blue" onClick={(e) => setStatus("predicted")}></StatisticsBox>
				<StatisticsBox size="normal" number={statistics != null ? statistics.CurrentIncidents: '..'} label="current incidents" color="blue" onClick={(e) => setStatus("current")}></StatisticsBox>
			 </div>
	 	</div>
  )
}


export default Statistics