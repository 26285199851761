 import * as React from "react"
 import Welcome from "./welcome"

 import "./dashboard.scss"
import Statistics from "./statistics"
import IncidentTypes from "./incident_types"
 
 const Dashboard = ({impactCallback, statusCallback}) => {
 
	return (
	  <div className="dashboard">
		<Welcome />
		<Statistics clickCallbackImpact={impactCallback} clickCallbackStatus={statusCallback} />
		<IncidentTypes />
	  </div>
	)
 }

 
 export default Dashboard