import React, {useState} from "react"
import PropTypes from "prop-types"
import { getCountries } from "../../services/api"

const CountryFilter = ({changeCallback, country}) => {
	const [countries, setCountries] = useState(null)

	let countryList = [];
	
	if (countries != null && countries.length > 0) {
		for(const key in countries) {
			countryList.push(
				<option value={countries[key].code} key={key}>{countries[key].name}</option>
				)
		}
	} else {
		getCountries(false).then(countries => {
			setCountries(countries)
		})
	}
	
  
  return (
	 	<div className="country-filter">
			 <label>Country</label>
			 <select name="country" onChange={changeCallback} value={country}>
				 <option value="" key="-1">All countries</option>
				 { countryList }
			 </select>
	 	</div>
  )
}

CountryFilter.propTypes = {
	  changeCallback: PropTypes.func.isRequired,
	  country: PropTypes.string
}

export default CountryFilter