import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import { Chart } from "react-google-charts"
import { getChartData } from "../../services/api"
import Loading from "../common/loading"

const IncidentType = ({size, impact}) => {
	const [chartData, setChartData] = useState(null)
	const [loading, setLoading] = useState(true)


	if(chartData == null) {
		getChartData(impact).then(chartData => {
			setChartData(chartData)
			setLoading(false)
		})
	}
	let classes = "content-box bubblechart-box"

	switch(size) {
		case 'small':
			classes += " col-sm-12 col-md-3"
			break
		case 'medium':
			classes += " col-sm-12 col-md-6"
			break;
		case 'large':
			classes += " col-sm-12"
			break;
	}

	let data = []
	if(chartData != null) {
		if(chartData.headers != null && chartData.data != null) {
			let headers = ['Incident type']
			for(let i = 0; i < chartData.headers.length; i++) {
				headers.push(chartData.headers[i])
			}
			
			data.push(headers)
			for (let i = 0; i < chartData.data.length; i++) {
				let row = [chartData.data[i].label]
				for(let key in chartData.data[i].values) {
					row.push(chartData.data[i].values[key])
				}
				data.push(row)
			}
		}
	}
	
	// const data = [
	// 	['Incident type', 'Heavy storm', 'Earthquake', 'Flood', 'War'],
	// 	['Jan-\'22', 3, 1, 4, 1],
	// 	['Feb-\'22', 2, 1, 3, 1],
	// 	['Mar-\'22', 3, 1, 1, 1],
	// 	['Apr-\'22', 1, 4, 1, 2]
	// ]

	const options = {
		'isStacked': 'absolute',
		height: '415',
		width: "100%",
		backgroundColor: 'transparent',
		title: 'Incident types - ' + impact,
		titleTextStyle: {
			color: '#fff'
		},
		colors: ['#32A1B0', '#B032A5', '#3244B0', '#B03249'],
		hAxis: {
			title: 'Time',
			titleTextStyle: {
				color: '#fff'
			},
			baselineColor: '#fff',
			gridlines: false,
			textStyle: {
				color: '#fff'
			},
			slantedText: true
		},
		vAxis: {
			title: '# of incidents',
			titleTextStyle: {
				color: '#fff'
			},
			baselineColor: '#fff',
			gridlines: false,
			textStyle: {
				color: '#fff'
			}
		},
		legend: {
			position: 'top',
			textStyle: {
				color: '#fff'
			}
		}
	}
	
 	let chartObject = (<Loading />)
	if(!loading) {
		if(data.length != 0){
			chartObject = (<Chart 
				chartType="ColumnChart"
				data={data}
				options={options}
				/>)
		}else{
			chartObject = (<>No incidents with size: {size} and impact: {impact}</>)
		}
	}
	
	
  return (
	 <div className={classes}>
		 {chartObject}
	 </div>
  )
}

IncidentType.propTypes = {
	size: PropTypes.string,
	impact: PropTypes.string
 }


export default IncidentType